<template>
  <div class="s-content content">
    <main class="row content__page">
      <section class="column large-full">
        <div class="row add-bottom">
          <div class="column large-full">
            <div class="row">
              <div class="column large-6">
                <h3>Your Works</h3>
              </div>
              <div class="column large-6">
                <div class="ss-custom-select">
                  <select class="full-width" @change="changeType">
                    <option value="0">Post</option>
                    <option value="2">Draft</option>
                    <option value="3">Note</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <router-link to="/writer">
                <button class="btn btn--primary" v-if="this.type == this.post">
                  <a href="#" class="icon icon-pencil"></a> New Post
                </button>
              </router-link>
              <button class="btn btn--primary" v-if="this.type == this.note" @click="updateNote(0, '')">
                <a href="#" class="icon icon-pencil"></a> New Note
              </button>
            </div>
            <div class="table-responsive">
              <table v-if="this.postList">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in this.dataList">
                    <td>
                      <span class="title post-title" :value="data.id" @click="update">
                        {{ data.title }}
                      </span>
                    </td>
                    <td>{{ data.typeName }}</td>
                    <td>
                      <span :value="data.id" @click="changeVisibility" style="cursor: pointer;">
                        {{ data.statusName }}
                      </span>
                    </td>
                    <td>{{ data.createdDate }}</td>
                  </tr>
                </tbody>
              </table>
              <table v-if="!this.postList">
                <thead>
                  <tr>
                    <th>Content</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in this.dataList">
                    <td>
                      <span class="title draft-title" :value="data.id" :data-content="data.title" @click="update">
                        {{ data.title }}
                      </span>
                    </td>
                    <td>
                      <span :value="data.id" @click="changeVisibility" style="cursor: pointer;">
                        {{ data.statusName }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> 
      </section>
    </main>
    <div class="row" v-if="paging.total > 0">
      <div class="column large-full">
        <nav class="pgn">
          <ul>
            <li><a class="pgn__prev" href="#" v-if="paging.page > 1">Prev</a></li>
            <li>
              <a class="pgn__num" href="#" v-for="index in paging.pageList" :class="{'current': index === paging.page, 'dots': index === 0}" :value="index">
                {{ index !== 0 ? index : '...' }}
              </a>
            </li>
            <li><a class="pgn__next" href="#" v-if="paging.page < paging.totalPage">Next</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from '@/assets/js/constants';

export default {
  name: 'Works',
  data() {
    return {
      dataList: [],
      dataMap: new Map(),
      type: 0,
      post: 0,
      draft: 2,
      note: 3,
      postList: false,
      paging: {
        page: 1,
        pageSize: 10,
        totalPage: 1,
        pageList: [],
        total: 0
      }
    }
  },
  props: [
    'isAuthorized'
  ],
  mounted() {
    if (!this.isAuthorized) {
      this.$router.push({ path: '/' });
    }
    this.postList = this.type === Constants.POST_TYPE.POST;
    this.$nextTick(function() {
      this.$func.loading(
        this.loadData
      );
    });
  },
  methods: {

    loadData: async function() {
      let api;
      let params = {
        params: {
          page: this.paging.page,
          pageSize: this.paging.pageSize,
          showAll: true,
          showQuote: false
        }
      }
      switch(this.type) {
        case Constants.POST_TYPE.POST: {
          api = '/post';
          break;
        }
        case Constants.POST_TYPE.DRAFT: {
          api = '/draft';
          break;
        }
        case Constants.POST_TYPE.NOTE: {
          api = '/note';
          break;
        }
      }
      let axios = this.$func.getAxios();
      await axios.get(api, params)
        .then(response => {
          this.dataList = response.data.data.items.map(item => {
            if (this.postList) {
              item['typeName'] = this.getTypeName(item.type);
            } else if (item.content) {
              item['title'] = item.content;
            }
            item['statusName'] = this.getStatusName(item.status);
            this.dataMap.set(item.id, item);
            return item;
          });
          this.paging.page = response.data.data.page;
          this.paging.totalPage = response.data.data.totalPage;
          this.paging.total = response.data.data.total;
          this.$func.setPageList(this.paging);
        })
        .catch(error => {
          this.$func.showErrorPopUpV1(error);
        });
    },

    update: function(e) {
      let id = Number(e.target.getAttribute('value'));
      switch(this.type) {
        case Constants.POST_TYPE.POST: {
          this.updatePost(id);
          break;
        }
        case Constants.POST_TYPE.DRAFT: {
          let content = e.target.getAttribute('data-content');
          this.updateDraft(id, content);
          break;
        }
        case Constants.POST_TYPE.NOTE: {
          let content = e.target.getAttribute('data-content');
          this.updateNote(id, content);
          break;
        }
      }
    },

    updatePost: async function(id) {
      const axios = this.$func.getAxios();
      let uri = '/post/'.concat(id);
      let params;
      await axios.get(uri)
        .then(response => {
          let data = response.data.data;
          params = {
            id: data.id,
            type: data.type,
            title: data.title,
            content: data.content,
            thumbnail: data.thumbnail ? data.thumbnail : '',
            selectedTags: data.tags ? JSON.stringify(data.tags) : '[]',
            slug: data.slug
          }
        })
        .catch(error => {
          this.$func.showErrorPopUpV1(error);
        });
        this.$router.push({
          name: 'Writer',
          params: params
        });
    },

    updateDraft: function(id, content) {
      this.$router.push({
        name: 'Draft',
        params: {
          id: 0,
          type: Constants.POST_TYPE.DRAFT,
          title: '',
          content: content,
          thumbnail: '',
          selectedTags: '[]',
          draftId: id
        }
      });
    },

    updateNote: function(id, content) {
      const axios = this.$func.getAxios();
      let popUpTitle = content === '' ? 'Add note' : 'Update note';
      let status;
      this.$swal.fire({
        title: popUpTitle,
        input: "textarea",
        inputValue: content,
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Save",
        showLoaderOnConfirm: true,
        preConfirm: async (data) => {
          await axios.post('/note', {
            id: id,
            content: data
          }).then(response => {
            status = response.data.data.status;
          });
          return;
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          if (status == Constants.STATUS.ACTIVATED) {
            this.$emit('reloadStickyNote');
          }
          this.$func.loading(
            this.loadData
          );
          this.$swal.fire({
            title: 'Done!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        }
      });
    },

    changeVisibility: function(e) {
      let id = Number(e.target.getAttribute('value'));
      let api;
      switch (this.type) {
        case Constants.POST_TYPE.POST: {
          api = '/post/'.concat(id);
          break;
        }
        case Constants.POST_TYPE.NOTE: {
          api = '/note/'.concat(id);
          break;
        }
        default: {
          return;
        }
      }
      let axios = this.$func.getAxios();
      axios.patch(api)
        .then(response => {
          this.dataList.map(item => {
            if (item.id !== id) return item;
            if (item.status === Constants.STATUS.ACTIVATED) {
              item.status = Constants.STATUS.INACTIVATED;
            } else {
              item.status = Constants.STATUS.ACTIVATED;
            }
            item.statusName = this.getStatusName(item.status);
            return item;
          });
          if (this.type === Constants.POST_TYPE.NOTE) {
            this.$emit('reloadStickyNote');
          }
        })
        .catch(error => {
          this.$func.showErrorPopUpV1(error);
        });
    },

    changeType: function(e) {
      this.type = Number(e.target.value);
      this.postList = this.type === Constants.POST_TYPE.POST;
      this.paging = {
        page: 1,
        pageSize: 10,
        totalPage: 1,
        pageList: [],
      }
      this.$func.loading(
        this.loadData
      );
    },

    getTypeName: function(type) {
      switch(type) {
        case Constants.POST_TYPE.POST: {
          return 'Post';
        }
        case Constants.POST_TYPE.QUOTE: {
          return 'Quote';
        }
      }
      return '-';
    },

    getStatusName: function(status) {
      switch(status) {
        case Constants.STATUS.INACTIVATED: {
          return 'Hidden';
        }
        case Constants.STATUS.ACTIVATED: {
          return 'Visible';
        }
      }
      return '-';
    }

  },
  watch: {
    isAuthorized: function(value) {
      if (!value) {
        this.$router.push({ path: '/' });
      }
    }
  }
}
</script>

<style scoped>
.ss-custom-select select {
  padding: 0 !important;
  height: 3rem !important;
  line-height: 2rem !important;
}

button > a {
  display: inline-block !important;
  margin: auto 0.6rem auto 0 !important;
  width: 1.1rem !important;
  height: 1.1rem !important;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  cursor: pointer;
}

.post-title {
  max-width: 250px;
}

.draft-title {
  max-width: 500px;
}
</style>
