<template>
  <div class="s-content">
    <main class="row content__page">
      <section class="column large-full">
        <div class="row">
          <div class="column large-12 tab-full">
            <h3>Hello, wanna write some shit?!</h3>
            <div>
              <div class="entry__post-thumb">
                <div class="thumb__container">
                  <img :src="this.thumbnail" alt="">
                </div>
              </div>
              <br>
              <div class="row">
                <div class="column large-6">
                  <label>Title</label>
                  <input class="full-width" type="text" placeholder="About time..." v-model="title">
                </div>
                <div class="column large-6">
                  <br>
                  <button class="btn full-width" href="#" @click="chooseImage">Upload thumbnail</button>
                  <input type="file" id="post-thumbnail" accept="image/*" @change="uploadThumbnail">
                </div>
              </div>
              <div class="row">
                <div class="column large-6">
                  <label>Tags</label>
                  <div class="ss-custom-select">
                    <select class="full-width" @change="selectTag" v-model="defaulTag">
                      <option disabled value="">Select Tags</option>
                      <option v-for="tag in tags" :value="tag.id">{{ tag.title }}</option>
                    </select>
                  </div>
                </div>
                <div class="column large-6">
                  <div class="entry__tags" style="margin-top: 0; margin-bottom: 3.2rem;">
                    <span class="entry__tag-list">
                      <a href="#" v-for="tag in selectedTags" :value="tag.id" @click="deselectTag">{{ tag.title }}</a>
                    </span>
                  </div>
                </div>
              </div>
              <md-editor v-model="content" language="en-US" @onUploadImg="uploadImage" @onSave="save"></md-editor>
              <br>
              <button @click="submit" class="btn btn--primary full-width" href="#">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Constants from '@/assets/js/constants.js';
import { MdEditor } from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';

export default {
  name: 'Writer',
  components: {
    MdEditor
  },
  data() {
    return {
      id: 0,
      type: Constants.POST_TYPE.DRAFT,
      title: '',
      content: 'Do your best!',
      thumbnail: '',
      defaulTag: '',
      tags: [],
      selectedTags: [],
      slug: '',
      draftId: 0
    }
  },
  props: [
    'isAuthorized'
  ],
  mounted() {
    if (!this.isAuthorized) {
      this.$router.push({ path: '/' });
    }
    this.loadData();
    this.$nextTick(function() {
      this.$func.loading(this.getTags);
    });
  },
  methods: {

    loadData: function() {
      this.id = this.$route.params.id;
      if (this.$route.params.draftId) {
        this.draftId = this.$route.params.draftId;
      }
      if (this.id && this.id != 0 || this.draftId != 0) {
        this.type = this.$route.params.type;
        this.title = this.$route.params.title;
        this.content = this.$route.params.content;
        this.thumbnail = this.$route.params.thumbnail;
        this.selectedTags = JSON.parse(this.$route.params.selectedTags);
        this.slug = this.$route.params.slug;
      }
      if (!this.thumbnail || this.thumbnail === '') {
        let images = this.$func.defaultImages;
        let random = Math.floor(Math.random() * images.length)
        this.thumbnail = images[random].src;
      }
    },

    getTags: function() {
      const axios = this.$func.getAxios();
      axios.get('/tag')
        .then(response => {
          this.tags = response.data.data.items;
          if (this.selectedTags.length > 0) {
            let selectedTagIds = this.selectedTags.map(item => item.id);
            this.tags = this.tags.filter(item => !selectedTagIds.includes(item.id));
          }
        })
    },

    selectTag: function(e) {
      let selectedValue = e.target.value;
      for (let tag of this.tags) {
        if (tag.id != selectedValue) continue;
        this.selectedTags.push(tag);
        this.tags = this.tags.filter(item => item.id != selectedValue);
        this.defaulTag = '';
      }
    },

    deselectTag: function(e) {
      let deselectedValue = e.target.getAttribute('value');
      for (let tag of this.selectedTags) {
        if (tag.id != deselectedValue) continue;
        this.tags.push(tag);
        this.selectedTags = this.selectedTags.filter(item => item.id != deselectedValue);
      }
      this.tags.sort((a, b) => a.id < b.id ? -1 : 1);
    },

    submit: function() {
      const data = {
        'id': Number(this.id),
        'title': this.title,
        'content': this.content,
        'type': Constants.POST_TYPE.POST,
        'thumbnail': this.thumbnail,
        'tags': this.selectedTags.map(item => item.id),
      }
      if (this.draftId && this.draftId != 0) {
        data['draftId'] = Number(this.draftId);
      }
      if (this.id && this.id != 0) {
        this.updatePost(data);
      } else {
        this.createPost(data);
      }
    },

    createPost: function(data) {
      const axios = this.$func.getAxios();
      axios.post('/post', data)
        .then(response => {
          this.$swal.fire({
            title: 'Nice Shit',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          .then(() => {
            this.$router.push({
              name: 'Home'
            });
          })
        })
        .catch(error => {
          this.$func.showErrorPopUpV1(error);
        });
    },

    updatePost: function(data) {
      const axios = this.$func.getAxios();
      axios.put('/post', data)
        .then(response => {
          this.$swal.fire({
            title: 'Nice Shit',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          .then(() => {
            if (response.data.data.status == 1) {
              this.$router.push({
                name: 'PostDetail',
                params: {
                  slug: this.slug,
                  postId: this.id
                }
              });
            } else {
              this.$router.push({
                name: 'Home'
              });
            }
          })
        })
        .catch(error => {
          this.$func.showErrorPopUpV1(error);
        });
    },

    uploadImage: async function(files, callback) {
      const axios = this.$func.getAxios();
      const res = await new Promise((resolve, reject) => {
        const headers = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        const form = new FormData();
        for (let file of files) {
          form.append('images', file);
        }
        axios.post('/file/image', form, headers)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
      callback(res.data.data.success.items.map(item => {
        return {
          url: process.env.VUE_APP_IMAGE_URL.concat(item),
          alt: 'image'
        }
      }));
    },

    chooseImage: function() {
      document.getElementById('post-thumbnail').click();
    },

    uploadThumbnail: async function() {
      let thumbnail = document.getElementById('post-thumbnail').files[0];
      const axios = this.$func.getAxios();
      await new Promise((resolve, reject) => {
        const headers = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        const form = new FormData();
        form.append('images', thumbnail);
        axios.post('/file/image', form, headers)
          .then((response) => {
            let thumbnailUri = response.data.data.success.items[0];
            this.thumbnail = process.env.VUE_APP_IMAGE_URL.concat(thumbnailUri);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    save: function() {
      if (this.type != Constants.POST_TYPE.DRAFT) return;
      const axios = this.$func.getAxios();
      const data = {
        'id': this.id,
        'content': this.content
      }
      axios.post('/draft', data)
        .then(response => {
          this.id = response.data.data.id;
          this.$swal.fire({
            title: 'Saving successfully!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .catch(error => {
          this.$swal.fire({
            title: 'Oops',
            text: 'Saving failed!',
            icon: 'error',
            confirmButtonColor: '#000000'
          });
        });
    }

  },
  watch: {
    isAuthorized: function(value) {
      if (!value) {
        this.$router.push({ path: '/' });
      }
    }
  }
}
</script>

<style scoped>
#post-thumbnail {
  display: none;
}
</style>