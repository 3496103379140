<template>
  <header class="s-header">
    <div class="header__top">
      <div class="header__logo">
        <a class="site-logo" href="/">
          <img src="/assets/images/logoes/logo.png" alt="Homepage">
        </a>
      </div>
      <div class="header__login" @keypress="login">
        <div class="header__login-form" action="#">
          <label>
            <span class="hide-content">Login</span>
            <input v-model="username" type="text" class="header__login-username" placeholder="Username">
            <input v-model="password" type="password" class="header__login-password" placeholder="Password">
          </label>
        </div>
      </div>
      <div class="header__search">
        <div class="header__search-form" action="#">
          <label>
            <span class="hide-content">Search for:</span>
            <input type="search" class="header__search-field" placeholder="Type Keywords" value="" name="s" title="Search for:" autocomplete="off">
          </label>
        </div>
        <a href="#" title="Close Search" class="header__search-close">Close</a>
      </div>
      <a href="#" class="header__search-trigger" @click="openSearchForm"></a>
      <a href="#" class="header__menu-toggle"><span>Menu</span></a>
    </div>
    <nav class="header__nav-wrap">
      <ul class="header__nav">
        <li @click="navigate('home')" :class="{'current': site === 'home'}">
          <router-link to="/">Home</router-link>
        </li>
        <li class="has-children">
          <a href="#" title="">Categories</a>
          <ul class="sub-menu">
            <li v-for="tag in tags">
              <a href="#" :value="tag.id" @click="listPost">{{ tag.title }}</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="header__action">
        <!-- <li class="ss-facebook">
          <a href="https://www.facebook.com/blabla.hahaha.hehehe" target="_blank">
            <span class="screen-reader-text">Facebook</span>
          </a>
        </li> -->
        <li class="icon-profile" v-if="this.isAuthorized">
          <a href="#">
            <span class="screen-reader-text">Profile</span>
          </a>
        </li>
        <li class="icon-works" @click="navigate('')" v-if="this.isAuthorized">
          <router-link to="/works">
            <span class="screen-reader-text">Continue your works</span>
          </router-link>
        </li>
        <li class="icon-login" v-if="!this.isAuthorized" @click="openLoginForm">
          <a href="#">
            <span class="screen-reader-text">Login</span>
          </a>
        </li>
        <li class="icon-logout" v-if="this.isAuthorized" @click="logout">
          <a href="#">
            <span class="screen-reader-text">Logout</span>
          </a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'SideMenu',
  data() {
    return {
      site: 'home',
      username: '',
      password: '',
      tags: []
    }
  },
  props: [
    'isAuthorized'
  ],
  mounted() {
    this.$nextTick(function() {
      this.$emit('authentication');
      this.$func.loading(this.getTags);
      this.$func.setupLoginForm();
    });
  },
  methods: {

    navigate: function(site) {
      this.site = site;
    },

    openSearchForm: function(e) {
      this.$func.openSearchForm(e);
    },

    openLoginForm: function(e) {
      this.$func.openLoginForm(e);
    },

    login: function(e) {
      if (e.keyCode != 13) return;
      const data = {
        'username': this.username,
        'password': this.password
      };
      const axios = this.$func.getAxios();
      axios.post('/auth/login', data)
        .then(response => {
          this.$swal.fire({
            title: 'Welcome back!',
            text: 'Let\'s make shittttt!!!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
          localStorage.setItem('userInfo', JSON.stringify(response.data.data));
          localStorage.setItem('token', 'Bearer '.concat(response.data.data.token));
          this.username = '';
          this.$func.closeLoginForm(e);
        })
        .catch(error => {
          this.$func.showErrorPopUp(error);
        })
        .finally(() => {
          this.password = '';
          this.$emit('authentication');
        });
    },

    logout: function() {
      this.$swal.fire({
        title: 'Oh, going so soon?',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#000000",
      }).then((result) => {
        if (result.isConfirmed) {
          const axios = this.$func.getAxios();
          axios.post('/auth/logout')
            .then(response => {
              localStorage.removeItem('userInfo');
              this.$router.push({ path: '/' });
            })
            .catch(error => {
              this.$swal.fire("Oops", "", "error");
            })
            .finally(() => {
              this.$emit('authentication');
            });
        }
      });
    },

    getTags: function() {
      const axios = this.$func.getAxios();
      axios.get('/tag')
        .then(response => {
          this.tags = response.data.data.items;
        });
    },

    listPost: function(e) {
      let currentRoutePath = this.$router.currentRoute.value.path;
      if (currentRoutePath === '/' || currentRoutePath === '/home') {
        this.$router.push({
          name: 'Home',
          replace: true,
          query: {
            tag: e.target.getAttribute('value')
          }
        }).then(() => {
          this.$router.go();
        });
      } else {
        this.$router.push({
          name: 'Home',
          params: {
            tag: e.target.getAttribute('value')
          }
        });
      }
    }
    
  }
}
</script>

<style scoped></style>
